







































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import DatetimePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";
import { AxiosResponse } from "axios";
import { CouponApi } from "@/networks/CouponApi";
@Component({
  components: { DatetimePicker }
})
export default class component_name extends Vue {
  types = [
    {
      text: "درصد",
      value: "percent"
    },
    {
      text: "مبلغ",
      value: "number"
    }
  ];
  form = {
    code: null,
    expire_at: null,
    amount: null,
    type: null,
    use_count: null
  };
  JalaaliDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  async submit() {
    let form = this.$refs.form as any;
    const validate = await form.validate();
    if (!validate) {
      return;
    }
    try {
      const res: AxiosResponse = await new CouponApi().create(this.form);
      this.$store.commit("showError", {
        message: res.data.message || "کوپن با موفقیت ساخته شد",
        color: "success"
      });
    } catch (err: any) {
      this.$store.commit("showError", {
        message: err.response.data.message || "خطای پیش بینی نشده رخ داده است",
        color: "error"
      });
      console.log(err);
    }
  }
}
